var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content" },
    [
      _c(
        "el-form",
        {
          attrs: { model: _vm.formData, "label-width": "98px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.formData.type == "input"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.formData.label } },
                [
                  _c("el-input", {
                    staticClass: "dialogInput",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formData.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "value", $$v)
                      },
                      expression: "formData.value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.type == "localSelect"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.formData.label } },
                [
                  _c("SelectLocal", {
                    attrs: {
                      row: _vm.formData,
                      option: _vm.formData.option,
                      filterable: _vm.formData.option.filterable || false,
                      multiple: _vm.formData.option.multiple || false,
                      disabled: _vm.formData.disabled,
                      clearable: _vm.formData.clearable || false,
                      "collapse-tags": _vm.formData["collapse-tags"],
                      placeholder:
                        _vm.formData.placeholder || "选择" + _vm.formData.label,
                    },
                    on: {
                      "update:row": function ($event) {
                        _vm.formData = $event
                      },
                    },
                    model: {
                      value: _vm.formData.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "value", $$v)
                      },
                      expression: "formData.value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("dialogEvent", "dialogClose")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirmBtn(_vm.formData.click)
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }