<template>
  <div class="dialog-content">
    <div class="content">
        <img width="100%" :src="formData.QRCodeImg" alt="加载中"/>
    </div>
  </div>
</template>
<script>

export default {
  name: 'WXVipCardQRCode',
  model: { prop: 'formData', event: 'Device' },
  props: {
    formData: {
      type: Object
    }
  },
}
</script>

<style lang="scss" scoped></style>
