var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "couponParameter" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "85px",
            model: _vm.couponPar,
            rules: _vm.rules,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "优惠券:" } }, [
            _c("span", [_vm._v(_vm._s(_vm.couponPar.couponCaseName))]),
          ]),
          _vm.isNumber
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.isText ? "投放张数" : "发放数量",
                    prop: "couponEmitQty",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "inputWidth",
                    attrs: { type: "number", size: "mini" },
                    model: {
                      value: _vm.couponPar.couponEmitQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponPar, "couponEmitQty", $$v)
                      },
                      expression: "couponPar.couponEmitQty",
                    },
                  }),
                  !_vm.isText
                    ? _c("span", { staticClass: "marL15" }, [
                        _vm._v("(单个会员)"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isQuantity
            ? _c(
                "el-form-item",
                { attrs: { label: "限领张数", prop: "couponEveryDrawQty" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.couponPar.couponDrawMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.couponPar, "couponDrawMode", $$v)
                        },
                        expression: "couponPar.couponDrawMode",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("每天")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("每周")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("每月")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("总共")]),
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticClass: "inputWidth",
                    attrs: { type: "number", size: "mini" },
                    model: {
                      value: _vm.couponPar.couponEveryDrawQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponPar, "couponEveryDrawQty", $$v)
                      },
                      expression: "couponPar.couponEveryDrawQty",
                    },
                  }),
                  _c("span", { staticClass: "marL15" }, [
                    _vm._v("(每人限领张数)"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isGrant
            ? _c(
                "el-form-item",
                { attrs: { label: "发放方式:" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.couponPar.couponEmitMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.couponPar, "couponEmitMode", $$v)
                        },
                        expression: "couponPar.couponEmitMode",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("即时发放"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("按天分期发放"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("按周分期发放"),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("按月分期发放"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.couponPar.couponEmitMode == 1 ||
                            _vm.couponPar.couponEmitMode == 2 ||
                            _vm.couponPar.couponEmitMode == 3,
                          expression:
                            "\n          couponPar.couponEmitMode == 1 ||\n            couponPar.couponEmitMode == 2 ||\n            couponPar.couponEmitMode == 3\n        ",
                        },
                      ],
                      staticClass: "marT10 distributionMethod",
                    },
                    [
                      _c("span", { staticClass: "fontS14 marR20" }, [
                        _vm._v("每次发放数量"),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.couponPar.couponEmitMode != 0
                                ? "couponEveryEmitQty"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "marR10",
                            attrs: { type: "number", size: "mini" },
                            model: {
                              value: _vm.couponPar.couponEveryEmitQty,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponPar,
                                  "couponEveryEmitQty",
                                  $$v
                                )
                              },
                              expression: "couponPar.couponEveryEmitQty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "fontS14 marR20" }, [
                        _vm._v(", 分期次数"),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.couponPar.couponEmitMode != 0
                                ? "couponEmitTimes"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "marR10",
                            attrs: { type: "number", size: "mini" },
                            model: {
                              value: _vm.couponPar.couponEmitTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponPar, "couponEmitTimes", $$v)
                              },
                              expression: "couponPar.couponEmitTimes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "fontS14 marR20" }, [
                        _vm._v(", 每次间隔"),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.couponPar.couponEmitMode != 0
                                ? "couponEmitInterval"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "marR10",
                            attrs: { type: "number", size: "mini" },
                            model: {
                              value: _vm.couponPar.couponEmitInterval,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponPar,
                                  "couponEmitInterval",
                                  $$v
                                )
                              },
                              expression: "couponPar.couponEmitInterval",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.couponPar.couponEmitMode == 1,
                              expression: "couponPar.couponEmitMode == 1",
                            },
                          ],
                          staticClass: "fontS14",
                        },
                        [_vm._v("天")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.couponPar.couponEmitMode == 2,
                              expression: "couponPar.couponEmitMode == 2",
                            },
                          ],
                          staticClass: "fontS14",
                        },
                        [_vm._v("周")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.couponPar.couponEmitMode == 3,
                              expression: "couponPar.couponEmitMode == 3",
                            },
                          ],
                          staticClass: "fontS14",
                        },
                        [_vm._v("月")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "生效方式:" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.couponPar.couponEffectMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.couponPar, "couponEffectMode", $$v)
                    },
                    expression: "couponPar.couponEffectMode",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "marB10 x-f" },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("即时生效"),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "生效后有效时长",
                            "label-width": "170px",
                            prop:
                              _vm.couponPar.couponEffectMode == 0
                                ? "couponValidDays"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth marR10",
                            staticStyle: { width: "120px !important" },
                            attrs: {
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              type: "number",
                              disabled: Boolean(
                                (_vm.couponPar.couponEffectMode |= 0)
                              ),
                              placeholder: "请输入",
                              size: "mini",
                            },
                            model: {
                              value: _vm.couponPar.couponValidDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponPar, "couponValidDays", $$v)
                              },
                              expression: "couponPar.couponValidDays",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "fontS14 marR20" }, [
                        _vm._v("天"),
                      ]),
                      _c(
                        "span",
                        { staticClass: "fontS14 annotateGrey marR20" },
                        [
                          _vm._v(
                            "(分期发放时，下一次发放的优惠券生效时，上一次未消费的优惠券自动过期)"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB10 x-f" },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("延迟生效"),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "领取",
                            "label-width": "100px",
                            prop:
                              _vm.couponPar.couponEffectMode == 1
                                ? "couponEffectDays"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth marR10",
                            staticStyle: { width: "120px !important" },
                            attrs: {
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              type: "number",
                              disabled: Boolean(
                                _vm.couponPar.couponEffectMode != 1
                              ),
                              placeholder: "请输入",
                              size: "mini",
                            },
                            model: {
                              value: _vm.couponPar.couponEffectDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponPar, "couponEffectDays", $$v)
                              },
                              expression: "couponPar.couponEffectDays",
                            },
                          }),
                          _c("span", { staticClass: "fontS14 marR20" }, [
                            _vm._v("天后生效"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: ", 生效后有效时长",
                            "label-width": "130px",
                            prop:
                              _vm.couponPar.couponEffectMode == 1
                                ? "couponValidDays"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth marR10",
                            staticStyle: { width: "120px !important" },
                            attrs: {
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              type: "number",
                              disabled: Boolean(
                                _vm.couponPar.couponEffectMode != 1
                              ),
                              placeholder: "请输入",
                              size: "mini",
                            },
                            model: {
                              value: _vm.couponPar.couponValidDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponPar, "couponValidDays", $$v)
                              },
                              expression: "couponPar.couponValidDays",
                            },
                          }),
                          _c("span", { staticClass: "fontS14 marR20" }, [
                            _vm._v("天"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "fontS14 annotateGrey marR20" },
                        [_vm._v("(优惠券生效时间从当天的00:00开始)")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB10 x-f" },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("固定日期生效"),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.couponPar.couponEffectMode == 2
                                ? "couponEffectDate"
                                : "",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticClass: "marR20",
                            attrs: {
                              disabled: Boolean(
                                _vm.couponPar.couponEffectMode != 2
                              ),
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              size: "mini",
                            },
                            model: {
                              value: _vm.couponPar.couponEffectDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponPar, "couponEffectDate", $$v)
                              },
                              expression: "couponPar.couponEffectDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: ", 生效后有效时长",
                            "label-width": "130px",
                            prop:
                              _vm.couponPar.couponEffectMode == 2
                                ? "couponValidDays"
                                : "",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth marR10",
                            staticStyle: { width: "120px !important" },
                            attrs: {
                              type: "number",
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              disabled: Boolean(
                                _vm.couponPar.couponEffectMode != 2
                              ),
                              placeholder: "请输入",
                              size: "mini",
                            },
                            model: {
                              value: _vm.couponPar.couponValidDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponPar, "couponValidDays", $$v)
                              },
                              expression: "couponPar.couponValidDays",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "fontS14 marR20" }, [
                        _vm._v("天"),
                      ]),
                      _c(
                        "span",
                        { staticClass: "fontS14 annotateGrey marR20" },
                        [_vm._v("(优惠券生效时间从当天的00:00开始)")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }