<template>
  <div class="dialog-content" v-if="formOpt.length && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="85px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="getCancel">取 消</el-button>
      <el-button type="primary" @click="getDialogEvent">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { categoryLiStTree } from "@/api/O2OThird/orderMealWxa/goods";
import { cakefreeselectCategoryLiStTree } from "@/api/O2OThird/cakeFreeSelectApp/goods";
export default {
  name: "O2OTreeGoodsCategory",
  mixins: [mixin],
  data() {
    return {
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "parentId",
        type: "tree",
        label: "上级类别",
        option: {
          selectChld: true,
          remote: async () => {
            let data = [];
            let url = undefined;
            url =
              this.data?.type == "cake"
                ? cakefreeselectCategoryLiStTree
                : categoryLiStTree;
            try {
              const res = await url({ isCategoryOutThree: true });
              if (res.data?.[0]) {
                let children = undefined;
                if (res.data[0].children) {
                  children = res.data[0].children.map((x) => ({
                    ...x,
                    children: undefined,
                  }));
                }
                data = [{ ...res.data[0], children }];
              }
            } catch (error) { }
            console.log("datadatadata", data);
            return { data };
          },
          label: "label",
          value: "id",
        },
        rules: [
          {
            required: true,
            message: "请选择上级类别",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        disabled: this.dialog.click !== "add",
        model: "categoryNo",
        type: "input",
        label: "类别编码",
        rules: [
          {
            required: true,
            message: "请输入类别编码",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "categoryName",
        type: "input",
        label: "类别名称",
        rules: [
          {
            required: true,
            message: "请输入类别名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "remark",
        type: "input",
        label: "备注",
        maxlength: "30",
      },
      // {
      //   model: 'imageUrl',
      //   type: 'upload-one',
      //   label: '分类图标',
      //   option: {
      //     listType: 'img',
      //     type: ['jpg', 'png'],
      //     size: '2 mb',
      //     tip: '建议图标尺寸100*100px、背景做透明处理，',
      //     autoUpload: true
      //   }
      // },
      {
        model: "posterImageUrl",
        type: "upload-one",
        label: this.data?.type == 'cake' ? '分类图片' : "分类海报",
        option: {
          listType: "img",
          type: ["jpg", "png"],
          size: "2 mb",
          autoUpload: true,
        },
      },
    ];
    await this.format(formOpt);
    await this.$nextTick();
    this.show = true;
  },
  methods: {
    getDialogEvent() {
      console.log('dialog', this.dialog);
      console.log('form', this.form);
      if (this.form.parentId != 0) {
        this.$message.error('只能添加一级分类，请选择全部分类！');
        return
      }
      this.dialogEvent(this.dialog.click)
    },
    getCancel() {
      this.dialog.show = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
}
</style>
