import { getRemindList } from '@/api/system/remind.js'
export const baseInfo = {
  loading: true,
  mutiSelect: true,
  listNo: true,
  hideCard: true,
  pagination: {
    total: 0,
    page: 1,
    size: 15
  },
  height: 500,
  getListApi: getRemindList,
  body: {}, // 请求内容
  defaultBody: { delFlag: '1' },
  title: '服务公告',
  check: [],
  rowKey: 'sysRemindId',
  columns: [
    {
      prop: 'sysRemindTypeName',
      label: '提醒类型',
      minWidth: 180,
      type: 'link',
      click: 'update'
    },
    {
      prop: 'sysRemindTitle',
      label: '提醒标题',
      type: 'html',
      minWidth: 180
    },
    {
      prop: 'sysRemindTimeName',
      label: '提醒时机',
      minWidth: 140
    },
    {
      prop: 'sysMenuPath',
      label: '关联菜单',
      minWidth: 130
    }
  ]
}
