<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="80px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";

export default {
  name: "VipChangeLevel",
  mixins: [mixin],
  data() {
    return {
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      this.$select({
        key: "viplevel",
        getModel: true,
        option: {
          rules: [
            {
              required: true,
              message: "请选择会员级别",
              trigger: ["blur", "change"],
            },
          ],
        },
      }),
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
