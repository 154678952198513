//键盘表
export const keys = {
    8: 'BackSpace',
    9: 'Tab',
    12: 'Clear',
    13: 'Enter',
    16: 'Shift',
    17: 'Ctrl',
    18: 'Alt',
    19: 'Pause',
    20: 'CapsLock',
    27: 'Escape',
    32: '空格',
    33: 'Prior',
    34: 'Next',
    35: 'End',
    36: 'Home',
    37: 'Left',
    38: 'Up',
    39: 'Right',
    40: 'Down',
    41: 'Select',
    42: 'Print',
    43: 'Execute',
    45: 'Insert',
    46: 'Delete',
    47: 'Help',
    48: 0,
    49: 1,
    50: 2,
    51: 3,
    52: 4,
    53: 5,
    54: 6,
    55: 7,
    56: 8,
    57: 9,
    65: 'A',
    66: 'B',
    67: 'C',
    68: 'D',
    69: 'E',
    70: 'F',
    71: 'G',
    72: 'H',
    73: 'I',
    74: 'J',
    75: 'K',
    76: 'L',
    77: 'M',
    78: 'N',
    79: 'O',
    80: 'P',
    81: 'Q',
    82: 'R',
    83: 'S',
    84: 'T',
    85: 'U',
    86: 'V',
    87: 'W',
    88: 'X',
    89: 'Y',
    90: 'Z',
    96: 0,
    97: 1,
    98: 2,
    99: 3,
    100: 4,
    101: 5,
    102: 6,
    103: 7,
    104: 8,
    105: 9,
    106: '*',
    107: '+',
    109: '-',
    110: '.',
    111: '/',
    112: 'F1',
    113: 'F2',
    114: 'F3',
    115: 'F4',
    116: 'F5',
    117: 'F6',
    118: 'F7',
    119: 'F8',
    120: 'F9',
    121: 'F10',
    122: 'F11',
    123: 'F12',
    124: 'F13',
    125: 'F14',
    126: 'F15',
    127: 'F16',
    128: 'F17',
    129: 'F18',
    130: 'F19',
    131: 'F20',
    132: 'F21',
    133: 'F22',
    134: 'F23',
    135: 'F24',
    136: 'Num',
    137: 'Scroll',
    186: ';',
    187: '=',
    188: '|',
    189: '-',
    190: '.',
    191: '/',
    192: '`',
    219: '[',
    221: ']',
}

export const numKeys = [
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105
]

export const sysKeys = [
    8,
    9,
    45,
    46,
    37,
    38,
    39,
    40
]


export const getHotKey = (e) => {
    return new Promise((resolve, reject) => {
        if (numKeys.includes(e.keyCode) && !e.ctrlKey && !e.altKey && !e.shiftKey) {
            reject("数值不支持设置为快捷键")
        }
        if (
            [13, 27, 32].includes(e.keyCode) &&
            !e.ctrlKey &&
            !e.altKey &&
            !e.shiftKey
        ) {
            reject("Esc、回车、空格不支持设置为快捷键")
        }
        if (sysKeys.includes(e.keyCode) && !e.ctrlKey && !e.altKey && !e.shiftKey) {
            reject("系统固定快捷键不支持设置为快捷键")
        }
        let hotKeyName = ''
        let hotKeyValue = []
        if (e.ctrlKey) {
            hotKeyName = keys[17];
            hotKeyValue = [17];
        }
        if (e.shiftKey) {
            hotKeyName = hotKeyName
                ? hotKeyName + `+${keys[16]}`
                : keys[16];
            hotKeyValue = hotKeyName
                ? [...hotKeyValue, 16]
                : [16];
        }
        if (e.altKey) {
            hotKeyName = hotKeyName
                ? hotKeyName + `+${keys[18]}`
                : keys[18];
            hotKeyValue = hotKeyName
                ? [...hotKeyValue, 18]
                : [18];
        }
        hotKeyName = hotKeyName
            ? hotKeyName + `+${keys[e.keyCode]}`
            : keys[e.keyCode];
        hotKeyValue = hotKeyName
            ? [...hotKeyValue, e.keyCode]
            : [e.keyCode];
        resolve({
            hotKeyName,hotKeyValue: hotKeyValue.join(',')
        })
    })
}