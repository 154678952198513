<template>
  <!-- 优惠券参数 -->
  <div class="couponParameter">
    <el-form ref="form" label-width="85px" :model="couponPar" :rules="rules">
      <el-form-item label="优惠券:">
        <span>{{ couponPar.couponCaseName}}</span>
      </el-form-item>
      <el-form-item v-if="isNumber" :label="isText? '投放张数' : '发放数量'" prop="couponEmitQty">
        <el-input
            class="inputWidth"
            type="number"
            v-model="couponPar.couponEmitQty"
            size="mini"
          />
        <span class="marL15" v-if="!isText">(单个会员)</span>
      </el-form-item>
      <el-form-item v-if="isQuantity" label="限领张数" prop="couponEveryDrawQty">
        <el-radio-group v-model="couponPar.couponDrawMode">
          <el-radio :label="0">每天</el-radio>
          <el-radio :label="1">每周</el-radio>
          <el-radio :label="2">每月</el-radio>
          <el-radio :label="3">总共</el-radio>
        </el-radio-group>
        <el-input
            class="inputWidth"
            type="number"
            v-model="couponPar.couponEveryDrawQty"
            size="mini"
          />
        <span class="marL15">(每人限领张数)</span>
      </el-form-item>
      <el-form-item v-if="isGrant" label="发放方式:">
        <el-radio-group v-model="couponPar.couponEmitMode">
          <el-radio :label="0">即时发放</el-radio>
          <el-radio :label="1">按天分期发放</el-radio>
          <el-radio :label="2">按周分期发放</el-radio>
          <el-radio :label="3">按月分期发放</el-radio>
        </el-radio-group>
        <div
          class="marT10 distributionMethod"
          v-show="
            couponPar.couponEmitMode == 1 ||
              couponPar.couponEmitMode == 2 ||
              couponPar.couponEmitMode == 3
          "
        >
          <span class="fontS14 marR20">每次发放数量</span>
          <el-form-item :prop="couponPar.couponEmitMode != 0 ? 'couponEveryEmitQty' : ''">
          <el-input
            class="marR10"
            type="number"
            v-model="couponPar.couponEveryEmitQty"
            size="mini"
          />
          </el-form-item>
          <span class="fontS14 marR20">, 分期次数</span>
          <el-form-item :prop="couponPar.couponEmitMode != 0 ? 'couponEmitTimes' : ''">
          <el-input
            class="marR10"
            type="number"
            v-model="couponPar.couponEmitTimes"
            size="mini"
          />
        </el-form-item>
          <span class="fontS14 marR20">, 每次间隔</span>
        <el-form-item :prop="couponPar.couponEmitMode != 0 ? 'couponEmitInterval' : ''">
          <el-input
            class="marR10"
            type="number"
            v-model="couponPar.couponEmitInterval"
            size="mini"
          />
        </el-form-item>
          <span v-show="couponPar.couponEmitMode == 1" class="fontS14"
            >天</span
          >
          <span v-show="couponPar.couponEmitMode == 2" class="fontS14"
            >周</span
          >
          <span v-show="couponPar.couponEmitMode == 3" class="fontS14"
            >月</span
          >
        </div>
      </el-form-item>
      <el-form-item label="生效方式:">
        <el-radio-group v-model="couponPar.couponEffectMode">
            <div class="marB10 x-f">
              <el-radio :label="0" >即时生效</el-radio>
              <el-form-item label="生效后有效时长" label-width="170px" :prop="couponPar.couponEffectMode == 0 ? 'couponValidDays' : ''">
                <el-input
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                class="inputWidth marR10"
                style="width: 120px !important;"
                type="number"
                v-model="couponPar.couponValidDays"
                :disabled="Boolean(couponPar.couponEffectMode |= 0)"
                placeholder="请输入"
                size="mini"
              />
              </el-form-item>
              <span class="fontS14 marR20">天</span>
              <span class="fontS14 annotateGrey marR20"
                >(分期发放时，下一次发放的优惠券生效时，上一次未消费的优惠券自动过期)</span
              >
            </div>
            <div class="marB10 x-f">
              <el-radio :label="1">延迟生效</el-radio>
              <el-form-item label="领取" label-width="100px" :prop="couponPar.couponEffectMode == 1 ? 'couponEffectDays' : ''">
                <el-input
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                class="inputWidth marR10"
                type="number"
                style="width: 120px !important;"
                :disabled="Boolean(couponPar.couponEffectMode != 1)"
                v-model="couponPar.couponEffectDays"
                placeholder="请输入"
                size="mini"
              />
              <span class="fontS14 marR20">天后生效</span>
              </el-form-item>
              <el-form-item label=", 生效后有效时长" label-width="130px" :prop="couponPar.couponEffectMode == 1 ? 'couponValidDays' : ''">
                <el-input
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                class="inputWidth marR10"
                style="width: 120px !important;"
                type="number"
                :disabled="Boolean(couponPar.couponEffectMode != 1)"
                v-model="couponPar.couponValidDays"
                placeholder="请输入"
                size="mini"

              />
              <span class="fontS14 marR20">天</span>
              </el-form-item>
              <span class="fontS14 annotateGrey marR20"
                >(优惠券生效时间从当天的00:00开始)</span
              >
            </div>
            <div class="marB10 x-f">
              <el-radio :label="2"
                >固定日期生效</el-radio
              >
              <el-form-item :prop="couponPar.couponEffectMode == 2 ? 'couponEffectDate' : ''">
                <el-date-picker
                class="marR20"
                v-model="couponPar.couponEffectDate"
                :disabled="Boolean(couponPar.couponEffectMode != 2)"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="mini"
              />
              </el-form-item>
              <el-form-item label=", 生效后有效时长" label-width="130px" :prop="couponPar.couponEffectMode == 2 ? 'couponValidDays' : ''">
                <el-input
                class="inputWidth marR10"
                style="width: 120px !important;"
                type="number"
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                :disabled="Boolean(couponPar.couponEffectMode != 2)"
                v-model="couponPar.couponValidDays"
                placeholder="请输入"
                size="mini"
              />
              </el-form-item>
              <span class="fontS14 marR20">天</span>
              <span class="fontS14 annotateGrey marR20"
                >(优惠券生效时间从当天的00:00开始)</span
              >
            </div>
          </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'CouponParameter',
  props: {
    // 是否展示张数
    isQuantity: {
      type: Boolean,
      default: false,
    },
    // 文案处理
    isText: {
      type: Boolean,
      default: false,
    },
    isNumber: {
      type: Boolean,
      default: true
    },
    isGrant: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      couponPar: {
        couponCaseName:undefined,//优惠券名称
        couponEmitQty:undefined,//发放数量
        couponEmitMode:0,//发放方式
        couponDrawMode:0,//限领张数 方式
        couponEveryEmitQty:undefined,//每次发放数量
        couponEmitTimes:undefined,//分期次数
        couponEmitInterval:undefined,//每次间隔
        couponEffectMode:0,//生效方式
        couponValidDays:undefined,//有效时长天数
        couponEffectDays:undefined,//延迟生效:领取几天后生效
        couponEffectDate:undefined,//固定日期生效:日期
      },
      rules:{
        couponEmitQty: [
            { required: true, message: '请输入发放数量', trigger: ['blur','change'] },
          ],
        couponEveryDrawQty: [
          { required: true, message: '请输入限领张数', trigger: ['blur','change'] },
        ],
        couponEveryEmitQty: [
            { required: true, message: '请输入', trigger: ['blur','change'] },
          ],
          couponEmitTimes: [
            { required: true, message: '请输入', trigger: ['blur','change'] },
          ],
          couponEmitInterval: [
            { required: true, message: '请输入', trigger: ['blur','change'] },
          ],
          couponValidDays:[
            { required: true, message: '请输入有效时长', trigger: ['blur','change'] },
          ],
          couponEffectDays:[
            { required: true, message: '请输入领取几天后生效', trigger: ['blur','change'] },
          ],
          couponEffectDate:[
            { required: true, message: '请选择固定日期', trigger: ['blur','change'] },
          ],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.couponParameter {
  padding: 20px;
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  //发放方式
  .distributionMethod {
    display: flex;
    width: 570px;
    height: 60px;
    background-color: rgb(229, 229, 229);
    font-size: 14px;
    margin-left: 8px;
    padding-top: 15px;
    padding-left: 20px;
    .el-input {
      width: 70px;
    }
  }
  .inputWidth{
    width: 120px;
  }
}
</style>
