import { deepCopy, isArray, haveContent, fcount } from '@/utils'
import { SYS_REMINDER_STATUS } from '@/utils/constant.js'
import { keys } from "./keycode.js";
export const mixin = {
  components: { myForm: () => import('@/components/my-form') },
  model: { prop: 'form', event: 'Device' },
  props: {
    form: {
      type: [String, Object],
      default: ''
    },
    dialog: {
      type: Object,
      default: () => ({})
    },
    dataObj: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    data: {
      get () {
        return this.dialog.data
      },
      set (e) {
        const dialog = this.dialog
        dialog.data = e
        this.$emit('update:dialog', dialog)
      }
    }
  },
  data () {
    return {
      load: false,
      loading: null
    }
  },
  methods: {
    async changeOption (e) {
      const addFormOpt = this.getAddOptions()
      const models = addFormOpt.map(x => x.model)
      const find = this.formOpt.find(x => models.includes(x.model))
      const form = deepCopy(this.form)
      addFormOpt.forEach(x => {
        form[x.model] = ''
      })
      this.$emit('Device', form)
      this.show = false
      try {
        this.$refs.myForm.$refs.value.clearValidate()
      } catch (error) {}
      await this.$nextTick()
      this.show = true
      if (e) {
        if (!find) {
          await this.format(
            [...deepCopy(this.formOpt), ...deepCopy(addFormOpt)],
            false
          )
        }
      } else {
        if (find) {
          await this.format(
            this.formOpt.slice(0, this.formOpt.length - addFormOpt.length),
            false
          )
        }
      }
    },
    // 初始化
    formatFormStart (formOpt) {
      let formData = deepCopy(this.form || {})
      switch (this.dialog.type) {
        case 'Remind':
          if (this.dialog.click === 'add') {
            formData.isStart = SYS_REMINDER_STATUS.start
          }
          if (formData.sysMenuId) {
            const getParentAreas = (pid, list) => {
              const target = []
              const o = list.find(item => item.menuId == pid) || {}
              if (JSON.stringify(o) !== '{}') target.push(o)
              if (o.parentId) target.push(...getParentAreas(o.parentId, list))
              return target
            }
            formData.sysMenuId = getParentAreas(
              formData.sysMenuId,
              this.dataObj.menuLevelList
            )
              .reverse()
              .map(x => x.menuId)
          }
          break
        case 'UpgradeLog':
          if (formData?.packageFileResp?.attachFileUrl) {
            formData.packageFile = formData?.packageFileResp?.attachFileUrl
          }
          break
        case 'QueryManSet':
          if (formData?.appManId) {
            formData.other1 = { employeeId: formData.appManId }
          }
          break
        case 'ScoreExchangeGoodsRule':
          if (this.dialog?.click === 'update') {
            formOpt[1].option.data = formData?.units || []
          }
          break
        case "HotKey":
          if (this.dialog?.click === 'update') {
            formData.hotKeyValue = this.form.hotKeyValue?.split(',') || []
            formData.hotKeyValueName = formData.hotKeyValue.map(item => keys[item]).join('+')
          }
          break;
        default:
          break
      }
      formOpt.forEach(x => {
        if (x.loadFormat) {
          formData[x.model] = x.loadFormat(formData[x.model], formData)
        }
      })
      return formData
    },
    async format (formOpt, reset = true) {
      const obj = this.formatFormStart(formOpt)
      formOpt.forEach(({ model, type, option }) => {
        if (!haveContent(obj[model])) {
          obj[model] = type === 'all' || option?.multiple ? [] : null
        }
      })
      this.$emit('Device', obj)
      await this.$nextTick()
      this.formOpt = deepCopy(formOpt)
      this.$emit('dialogEvent', 'formLoad', deepCopy(obj))
      if (reset) {
        try {
          await this.$nextTick()
          // 表单重置
          this.$refs.myForm.$refs.value.resetFields()
          this.$refs.myForm.$refs.value.clearValidate()
        } catch (error) {}
      }
    },
    showLoading () {
      this.loading = this.$loading({
        lock: true,
        text: '提交中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
        customClass: 'topLoading'
      })
    },
    closeLoading () {
      try {
        this.loading.close()
      } catch (error) {}
    },
    formatForm (form = false) {
      let formData = deepCopy(form || this.form)
      switch (this.dialog.type) {
        case 'Remind':
          if (isArray(formData.sysMenuId)) {
            formData = { ...formData, sysMenuId: formData.sysMenuId.pop() }
          }
          break
        case 'UpgradeLog':
          delete formData.status
          break
        case 'ImportWXTemplate':
          if (formData.wxAppType == '1') {
            formData.templateIdShort = formData.templateNo
          } else {
            formData.tId = formData.templateNo
            formData.sceneDesc = formData.templateRemark
          }
          break
        case 'ScoreExchangeGoodsRule':
        case 'ScoreExchangeCouponRule':
          formData.startTime = formData.datetime[0]
          formData.endTime = formData.datetime[1]
          formData.isAllowOfflineShop = formData.AllShop.includes('0')
          formData.isAllowOnlineShop = formData.AllShop.includes('1')
          break
        case 'PrintTemplate':
          if (isArray(formData.menuId)) {
            formData.menuId = formData.menuId.pop()
          }
          break
        case 'HotKey': 
          formData.hotKeyValue = isArray(formData.hotKeyValue) ? formData.hotKeyValue.join(',') : formData.hotKeyValue
          break
        default:
          break
      }
      this.formOpt.forEach(x => {
        if (x.delete) {
          delete formData[x.model]
        }
        if (x.reqFormat) {
          formData[x.model] = x.reqFormat(formData[x.model])
        }
      })
      return formData
    },
    async categorySubmit () {
      try {
        await this.$refs.form.validate()
        this.$emit('dialogEvent', 'SourceDialogChange', this.options)
      } catch (error) {
        this.$message.error('请输入必填项')
      }
    },

    submit () {
      console.log(this.options, 'this.options')
      if (this.options.check.length == 0)
        return this.$message.error('请选择源单')
      let obj = {
        list: this.options.check,
        index: this.options.tabColumnIndex,
        tabColumnType: this.options.tabColumnType
      }
      this.$emit('dialogEvent', 'SourceDialogChange', obj)
    },
    userClick (type) {
      this.$emit('dialogEvent', 'SourceOperation', type)
    },
    async subClick () {
      let optionsIndex = this.options.list.findIndex(
        item =>
          item.bankAccountNo == '' ||
          item.bankAccountNo == undefined ||
          item.payModeNo == '' ||
          item.payModeNo == undefined ||
          item.payMoney == '' ||
          item.payMoney == undefined
      )
      console.log(optionsIndex, 'optionsIndex')
      console.log(this.options.list, 'this.options.list')

      if (optionsIndex != '-1' || this.options.list.length == 0)
        return this.$message.error(
          `请先录入${this.billType == '190101' ? '收' : '付'}款信息`
        )
      let payMoneyTotal = 0
      payMoneyTotal = this.options.list.reduce((prev, next) => {
        return prev + Number(next.payMoney)
      }, 0)
      let discountMoney = this.discountMoney ? this.discountMoney : 0

      if (fcount([payMoneyTotal, discountMoney], '+') != this.exceedAmount)
        return this.$message.error('预收总金额”不等于“超收转预收金额”，请检查')
      let rows = {
        list: this.options.list,
        exceedAmount: this.exceedAmount,
        discountMoney: this.discountMoney
      }
      this.$emit('dialogEvent', 'SourceCollection', rows)
    },
    async dialogEvent (click, close = true) {
      let dataRes = false
      try {
        await (this.$refs.myForm.$refs.value || this.$refs.myForm).validate()
      } catch (error) {
        this.$message.error(Object.values(error)[0][0].message)
        return dataRes
      }
      const done = () => {
        if (close) {
          this.$emit('dialogEvent', 'dialogChange')
        }
      }
      const { update, add } = { ...this.requests, ...this.dialog?.api }
      switch (click) {
        case 'add': // 选中数据 Array
        case 'add-save':
          this.showLoading()
          try {
            const res = await add(this.formatForm())
            this.form.formApiRes = res
            this.$message.success('新增成功！')
            if (click == 'add') {
              done()
              dataRes = true
            } else {
              this.$refs.myForm.$refs.value.resetFields()
            }
          } catch (error) {
            console.log('dialog add err-', error)
          }
          break
        case 'update': // 选中数据 Array
        case 'update-save': // 选中数据 Array
          this.showLoading()
          try {
            const form = this.formatForm()
            switch (this.dialog.type) {
              case 'UpgradeLog':
                delete form.upgradeProductVer
                break
              default:
                break
            }
            await update(form)
            this.$message.success('编辑成功！')
            if (click == 'update') {
              done()
              dataRes = true
            } else {
              this.$refs.myForm.$refs.value.resetFields()
            }
          } catch (error) {}
          break
        case 'delivery': // 选中数据 Array
          this.showLoading()
          try {
            await add(this.formatForm())
            this.$message.success('操作成功！')
            done()
            dataRes = true
          } catch (error) {}
          break
        default:
          this.$emit('dialogEvent', click)
          break
      }
      this.closeLoading()
      return dataRes
    }
  }
}
