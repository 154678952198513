var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c("div", { staticClass: "content" }, [
      _c("img", {
        attrs: { width: "100%", src: _vm.formData.QRCodeImg, alt: "加载中" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }