<template>
  <div>
    <template>
      <span>{{ label }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DictBusiness',
  props: {
    options: {
      type: Array,
      default: null
    },
    value: {
      type: String || Number,
      default: null
    }
  },
  data () {
    return {
      label: undefined
    }
  },
  created () {
    this.options.forEach(v => {
      if (v.dictValue === this.value) {
        this.label = v.dictLabel
      }
    })
  }
}
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
