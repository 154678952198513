<template>
  <div class="dialog-content">
    <el-form :model="formData" label-width="98px" @submit.native.prevent>
      <el-form-item :label="formData.label" v-if="formData.type == 'input'">
        <el-input
          class="dialogInput"
          v-model="formData.value"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item :label="formData.label" v-if="formData.type == 'localSelect'">
        <SelectLocal
        v-model="formData.value"
        :row.sync="formData"
        :option="formData.option"
        :filterable="formData.option.filterable || false"
        :multiple="formData.option.multiple || false"
        :disabled="formData.disabled"
        :clearable="formData.clearable || false"
        :collapse-tags="formData['collapse-tags']"
        :placeholder="formData.placeholder || `选择${formData.label}`"
      />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')"
        >取消</el-button
      >
      <el-button type="primary" @click="confirmBtn(formData.click)"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
export default {
  name: 'VipBatchUpdate',
  components:{ SelectLocal },
  model: { prop: 'formData', event: 'Device' },
  props: {
    formData: {
      type: Object
    }
  },
  methods:{
    confirmBtn (click) {
      this.$emit('dialogEvent',  click)
      this.$emit('dialogEvent', 'dialogClose')
    }
  }
}
</script>
<style scoped lang="scss">
.el-form{
  padding: 20px 20px 0;
}
</style>
