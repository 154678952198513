<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm
        v-model="form"
        ref="myForm"
        :options="formOpt"
        label-width="100px"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="confirm()">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from '@/components/Dialog/components/mixin.js'
import { msgUpdate } from '@/api/vip/wxService/msgTemplate.js' //微信消息的模板
export default {
  name: 'SetWXMsgType',
  mixins: [mixin],
  data () {
    return {
      show: false,
      formOpt: []
    }
  },
  async created () {
    let formoptList = JSON.parse(this.form.wxParamList)
    formoptList.forEach(item => {
      this.form[item.keyword] = item.keyword
    })
    let selectOption = this.form.msgTypeData
    selectOption = selectOption.map(item => ({ key: item, value: item }))
    let formOpt = formoptList.map(item => {
      return {
        label: item.name,
        type: 'local',
        model: item.keyword,
        option: {
          data: selectOption,
          label: 'key',
          value: 'value'
        },
        rules: [
          {
            required: true,
            message: `请选择${item.name}`,
            trigger: ['blur', 'change']
          }
        ]
      }
    })
    await this.format(formOpt)
    this.show = true
  },
  methods: {
    async confirm () {
        try {
        await this.$refs.myForm.$refs.value.validate()
      } catch (error) {
        this.$message.error(Object.values(error)[0][0].message)
        return 
      }
      let formoptList = JSON.parse(this.form.wxParamList)
      formoptList = formoptList.map(item => ({...item,keyword: this.form[item.keyword] || ''}))
      try {
        await msgUpdate({
          templetMsgId: this.form.templetMsgId,
          wxParamList: JSON.stringify(formoptList)
        })
        this.$message.success('设置成功')
        this.$emit('dialogEvent', 'dialogChange')
      } catch (err) {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
